// Booster Next FY
@font-face {
  font-family: 'BoosterNextFY';
  src: url('/assets/fonts/booster-next-fy/BoosterNextFY-Bold_latin-ext.woff2') format('woff2'),
  url('/assets/fonts/booster-next-fy/BoosterNextFY-Bold_latin-ext.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoosterNextFY';
  src: url('/assets/fonts/booster-next-fy/BoosterNextFY-Medium_latin-ext.woff2') format('woff2'),
  url('/assets/fonts/booster-next-fy/BoosterNextFY-Medium_latin-ext.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
