// ----------------------- buttons -----------------------
.button-style {
  color: $light-primary-text;
  border-radius: 50px !important;
  height: 50px;
  font-size: $button-font-size;
}

// mat-flat
.mat-flat-button {
  @extend .button-style;

  &.mat-primary {
    color: $light-primary-text;
    background-color: $app-color-dark;
  }

  &.mat-secondary {
    color: $light-primary-text;
    background-color: $app-color-light;
  }

  &.mat-accent {
    color: $light-primary-text;
    background-color: $app-contrast;
  }

  &.mat-warn {
    color: $light-primary-text;
  }

  &.mat-success {
    background-color: map-get($mat-success, main);
    color: $light-primary-text;
  }

  &.mat-button-disabled {
    &.mat-primary {
      color: $light-primary-text;
      background: rgba(map-get($mat-primary, main), 0.5);
    }

    &.mat-accent {
      color: $light-primary-text;
      background: rgba(map-get($mat-accent, main), 0.5);
    }
  }
}

// mat-stroked
.mat-stroked-button {
  @extend .button-style;

  background-color: #FFFFFF;

  &.mat-primary {
    border: 3px solid map-get($mat-primary, main);
    color: map-get($mat-primary, main);
  }

  &.mat-accent {
    border: 3px solid map-get($mat-accent, main);
    color: map-get($mat-accent, main);
  }
}

// ----------------------- inputs -----------------------

// mat-form-field
mat-form-field.mat-form-field {
  &.mat-focused {
    .mat-form-field-label {
      color: $app-font-color-light;
    }

    .mat-form-field-ripple {
      background-color: $app-font-color-light;
    }
  }

  .mat-icon {
    color: $app-font-color-light;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $app-font-color-light;
  background-color: white;
}

.mat-form-field-required-marker {
  color: $app-font-color-light;
}

.mat-form-field-label.mat-focused,
.mat-form-field-label {
  color: $app-font-color-light;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $app-font-color-light;
}

.mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field-outline .mat-form-field-outline-end,
.mat-form-field-outline .mat-form-field-outline-gap {
  border-width: 2px !important;
}

// mat-input
input.mat-input-element {
  color: $app-font-color-light;
}

// mat checkbox
.mat-checkbox-layout{
  display: unset !important;
  white-space: break-spaces !important;
}

