// Animations
@keyframes pulse {
  0% {
    box-shadow: 0 0 $dim-single 1px #219f19;
  }

  50% {
    box-shadow: 0 0 $dim-single 3px #219f19, 0 0 $dim-double 2px #219f19, 0 0 $dim-treble 1px #219f19;
  }

  100% {
    box-shadow: 0 0 $dim-single 1px #219f19;
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  40% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  50% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}
