// Info message triggered on portrait device orientation
.orientation-message {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2dd36f;
  padding: $dim-treble;

  img {
    display: block;
    width: 15vh;
    margin: 5vw auto;
    animation: slide-roll 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  @include portrait {
    display: flex;
  }

  h1 {
    font-size: $dim-single*2.5;
    text-align: center;
    line-height: 1;
    color: #ffffff;
    margin: 0;
  }
}

.page {
  display: block;

  @include portrait {
    display: none;
  }
}

// Column layout
.columns {
  display: grid;

  &.col-1-1 {
    grid-template-columns: 1fr 1fr;
  }

  &.gap-x1 {
    grid-gap: $dim-single;
  }

  &.gap-x2 {
    grid-gap: $dim-double;
  }
}

// Main content layout
.page-layout {
  background: no-repeat  center center / cover;
  width: 100vw;
  height: calc(100vh - #{$dim-header-height-tablet});
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  @include phone {
    height: calc(100vh - #{$dim-header-height-phone});
    grid-template-columns: 1fr 2fr;
  }
}

// Header
.page-header {
  width: 100%;
  height: $dim-header-height-tablet;
  background: #6624fb;
  display: grid;
  grid-column-gap: $dim-double;
  padding: $dim-single $dim-double $dim-single $dim-double;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  position: relative;

  @include phone {
    height: $dim-header-height-phone;
  }

  h1 {
    font-size: $dim-single*2.5;
    text-align: center;
    line-height: 1;
    color: #fff;
    margin: $dim-single 0 0 0;

    @include phone {
      font-size: $dim-single*4;
    }
  }

  // Round buttons (here used in header only)
  .ui-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    width: $dim-double*2;
    height: $dim-double*2;
    text-align: center;

    @include phone {
      width: $dim-quad;
      height: $dim-quad;
    }

    &.transparent {
      background-color: transparent;
    }

    &.circle {
      background-color: #fff;
      border-radius: 100%;
    }

    img {
      display: block;
      width: $dim-icon-tablet;
      height: $dim-icon-tablet;

      @include phone {
        width: $dim-icon-phone;
        height: $dim-icon-phone;
      }
    }
  }

  // Header start/gem status
  .wallet {
    font-size: $dim-single*1.75;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    margin-top: $dim-single*1.5;

    @include phone {
      font-size: $dim-single*3;
    }

    img {
      width: auto;
      height: $dim-icon-tablet;
      margin-right: $dim-single*.5;
      vertical-align: middle;
      position: relative;
      top: -2px;
      display: inline-block;

      @include phone {
        height: $dim-icon-phone;
      }
    }

    .spacer {
      width: $dim-treble;
    }
  }
}

// ** Buttons
.ui-button-action {
  font-size: $dim-single*2.25;
  font-weight: bold;
  line-height: 1.15;
  color: #fff;
  text-align: center;
  display: block;
  padding: $dim-double;
  border-radius: $dim-single;
  background: rgb(81, 24, 239);
  background: linear-gradient(0deg, rgba(81, 24, 239, 1) 0%, rgba(134, 98, 247, 1) 100%);

  @include phone {
    font-size: $dim-single*3.25;
    margin: 0;
  }
}

.ui-button-big {
  font-size: $dim-single*2.25;
  font-weight: bold;
  line-height: 1.15;
  color: #fff;
  text-align: center;
  display: block;
  padding: $dim-double;
  border-radius: $dim-treble*2;
  background-color: #fea027;

  &:not(:last-child) {
    margin-bottom: $dim-double;
  }

  @include phone {
    font-size: $dim-single*3.25;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: $dim-treble;
    }
  }
}

.ui-button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: $dim-double*3;
  height: $dim-double*3;
  text-align: center;
  border-radius: 100%;
  background-color: rgba(255,255,255,.33);

  @include phone {
    width: $dim-quad;
    height: $dim-quad;
  }

  img {
    display: block;
    width: $dim-icon-tablet;
    height: $dim-icon-tablet;

    @include phone {
      width: $dim-icon-phone;
      height: $dim-icon-phone;
    }
  }
}

